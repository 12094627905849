import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/about.scss"

export default function projekter() {
  return (
    <Layout>
      <Seo title="Steen Lauth" />
      <div className="about">
        <div className="intro">
          <StaticImage
            className="introImage"
            src={"../images/about/about1.jpeg"}
            quality={95}
            alt="Steen Lauth | Tømrer og snedker "
          />

          <div className="introText">
            <h1>Min Filosofi</h1>
            <p>
              Det jeg tilbyder er, at det er mig, der bygger jeres hus - ikke
              ansatte svende. Det betyder, at der er kort vej mellem de ønsker I
              har, og det byggede resultat. Jeg har ikke flere huse i gang
              samtidigt Det giver kontinuitet og fremdrift i byggeriet og en
              sikkerhed for jer om aflevering til tiden.
            </p>
          </div>
        </div>

        <h2>Snedker, Byggeleder, Entreprenør</h2>
        <div className="aboutGrid">
          <StaticImage
            className="itemA"
            src={"../images/about/about2.jpeg"}
            quality={95}
            alt="Steen Lauth | Tømrer og snedker "
          />
          <p className="quote itemB textBox">
            Jeg har en kærlighed for godt håndværk og smukke huse, og det er det
            som driver mig
          </p>
          <div className="itemC">
            <StaticImage
              className="itemCimage"
              src={"../images/about/about3.jpeg"}
              quality={95}
              alt="Steen Lauth | Tømrer og snedker "
            />
            <div className="overlay">
              <p>
                Efter en bachelor i økonomi, besluttede jeg mig for at blive
                selvstændig inden for mit oprindelige fag som snedker. Det er
                glæden ved håndværket og oplevelsen af at skabe noget fysisk i
                samarbejde med bygherre og arkitekter, der driver værket for
                mig.
              </p>
            </div>
          </div>
          <StaticImage
            className="itemD"
            src={"../images/about/about4.jpeg"}
            alt="Steen Lauth | Tømrer og snedker "
          />
          <div className="textBox itemE">
            <p>
              Min målsætning og interesse er at bygge huse i så bæredygtige
              materialer som muligt. Det er min fordel som snedker/tømrer, at
              træ ligger lige for som bæredygtigt byggemateriale.
            </p>
            <p>
              Bygningsreglementet sætter rammerne, og det er op til bygherre og
              dygtige arkitekter at finde de smukke og bæredygtige løsninger.
              Gerne i dialog med mig. <br />
            </p>
            <p> De smukkeste løsninger er de mest bæredygtige løsninger.</p>
            <p>
              Jeg arbejder i øjeblikket på et udviklingsprojekt, hvor idéen er
              at bygge små smukke huse, som sætter et så lille klimaaftryk som
              muligt.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
